import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../config/APIConfig';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import '../assets/style.scss'
import logo from '../assets/logo.png'

function SignUpPage() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authLevel, setAuthLevel] = useState(2);
    const [nip, setNip] = useState('');
    const authOptions = [
        { name: 'Pengguna', value: 2 },
        { name: 'Admin', value: 1 },
    ];

    const handleBtn = (e) => {
        if (e.target.innerText === "Masuk"){
            navigate("/", {
                replace: true
            });
        }
    }

    const handleSignup = async () => {
        if(fullName && nip && username && password){
            try {
                await axios
                .post(API_URL + 'auth/register',
                    {
                        fullName,
                        username,
                        password,
                        nip,
                        authLevel: authLevel ? authLevel : 2
                    },
                    {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
                .then((res) => {
                    if(res.data.result){
                        toast.current.show({ severity: 'success', summary: 'Sukses', detail: "Pendaftaran berhasil!", life: 5000});
                        navigate("/", {
                            replace: true
                        });
                    }
                });
            } catch (error) {
                console.error(error.message);
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Peringatan', detail: "Semua Kolom Tidak Boleh Kosong!", life: 5000});
        }
    };

  return (
    <div className='h-screen flex justify-content-center align-items-center'>
        <Toast ref={toast} />
        <Card title="Kenaikan Gaji Berkala Pengadilan Negeri Tondano" className='h-7 w-8'>
            <div className="flex flex-column md:flex-row">
                <div className="w-full md:w-5 flex flex-column align-items-center justify-content-center gap-4 pt-5">
                    <span className="p-float-label">
                        <InputText id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        <label htmlFor="fullName">
                            Nama Lengkap
                        </label>
                    </span>
                    <span className="p-float-label">
                        <InputText id="nip" value={nip} onChange={(e) => setNip(e.target.value)} />
                        <label htmlFor="nip">
                            NIP
                        </label>
                    </span>
                    <span className="p-float-label">
                        <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <label htmlFor="username">
                            Username
                        </label>
                    </span>
                    <span className="p-float-label">
                        <InputText id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <label htmlFor="password">
                            Password
                        </label>
                    </span>
                    {/* <span className="p-float-label">
                        <Dropdown inputId="authLevel" value={authLevel} onChange={(e) => setAuthLevel(e.value)} options={authOptions} optionLabel="name" style={{width: 250}}/>
                        <label htmlFor="authLevel">Daftar Sebagai</label>
                    </span> */}
                    <Button id='signUp' label="Daftar" icon="pi pi-user" className="w-10rem" onClick={handleSignup}></Button>
                </div>
                <div className="w-full md:w-2">
                    <Divider layout="vertical" className="hidden md:flex">
                        <b>Atau</b>
                    </Divider>
                    <Divider layout="horizontal" className="flex md:hidden" align="center">
                        <b>Atau</b>
                    </Divider>
                </div>
                <div className="w-full md:w-5 flex align-items-center justify-content-center py-5 relative">
                    <img src={logo} alt='logo' className='absolute top-0 w-7rem'/>
                    <Button id='login' label="Masuk" icon="pi pi-user-plus" className="p-button-success" onClick={(e) => handleBtn(e)}></Button>
                </div>
            </div>
        </Card>
    </div>
  );
}

export default SignUpPage;
