import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config/APIConfig';
import '../assets/style.scss'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

const NominatifManual = () => {
  const toast = useRef(null);
  const [pegawai, setPegawai] = useState([]);
  const [masaKerja, setMasaKerja] = useState(0)
  const [gajiLama, setGajiLama] = useState('');
  const [gajiBaru, setGajiBaru] = useState('');
  const [tmt, setTmt] = useState(new Date());
  const [noDokumen, setNoDokumen] = useState('');
  const [noSkTerakhir, setNoSkTerakhir] = useState('');
  const [tglSurat, setTglSurat] = useState(new Date());
  const [tglSkTerakhir, setTglSkTerakhir] = useState(new Date());
  const [selectedPegawai, setSelectedPegawai] = useState()

  const cardHeader = (
    <h2 className='mx-3'>Nominatif Manual</h2>
  )

  const tambahData = async() => {
    try {
      await axios
      .post(API_URL + 'nominatif/add',
      {
        idPegawai: selectedPegawai.id,
        gajiLama: gajiLama.gajiPokok,
        gajiBaru: gajiBaru.gajiPokok,
        masaKerjaBaru: gajiBaru.masaKerja,
        tmt: tmt,
        noDokumen: noDokumen,
        noSkTerakhir: noSkTerakhir,
        tglSurat: tglSurat,
        tglSkTerakhir: tglSkTerakhir,
        GolonganIdBaru: gajiBaru.id
      },
      {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
      .then((res) => {
        if(res.data.result){
          toast.current.show({ severity: 'success', summary: 'Sukses', detail: res.data.msg, life: 5000 });
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }

  useEffect(() => {    
    try {
      axios
      .get(API_URL + 'pegawai')
      .then((res) => {
          if(res.data.result){
              setPegawai(res.data.pegawai)
          } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
          }
    });
    } catch (error) {
      console.error(error.message);
    }
  },[])

  useEffect(() => {
    if(selectedPegawai){
      async function checkGaji() {
        try {
          await axios
          .post(API_URL + 'pegawai/gaji',
          {
            golongan: selectedPegawai?.golongan,
            masaKerja: selectedPegawai?.masaKerja
          },
          {headers: {
            Accept: "*/*",
            Authorization: sessionStorage.getItem("accessToken")
          }})
          .then((res) => {
            if(res.data.result){
              setGajiLama(res.data.gaji[0])
              setGajiBaru(res.data.gaji[1])
            } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
      
      checkGaji()
    }
  }, [selectedPegawai])

  return (
    <div className='grid p-7'>
      <Toast ref={toast} />
      <div className='col-12'>
        <Card header={cardHeader} className='relative overflow-hidden p-2' style={{backgroundColor: '#b0c7bf', boxShadow: '10px 9px 15px 2px #565656'}}>
          <div className=' surface-0 p-4 pt-6'>
            <div className='grid row-gap-4'>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <Dropdown className='w-full' value={selectedPegawai} options={pegawai} optionLabel='fullName' onChange={(e) => setSelectedPegawai(e.value)}></Dropdown>
                <label htmlFor="dd-city">Pilih Pegawai</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <InputText className='w-full' keyfilter="int" value={selectedPegawai?.masaKerja}></InputText>
                <label htmlFor="dd-city">Masa Kerja (Tahun)</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <InputText value={selectedPegawai ? `${selectedPegawai?.golonganDetail?.ket} / ${selectedPegawai?.golongan}` : '' } className='w-full surface-200' readOnly></InputText>
                <label htmlFor="dd-city">Pangkat/Golong</label>
              </span>
              <span className='md:col-3 sm:col-12 p-float-label'>
                <InputNumber className='w-full' mode="currency" currency="IDR" locale="id-ID" value={gajiLama.gajiPokok} readOnly></InputNumber>
                <label htmlFor="dd-city">Nominal Gaji Lama</label>
              </span>
              <span className='md:col-3 sm:col-12 p-float-label'>
                <InputNumber className='w-full' mode="currency" currency="IDR" locale="id-ID" value={gajiBaru.gajiPokok} readOnly></InputNumber>
                <label htmlFor="dd-city">Nominal Gaji Baru</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <InputText value="Pengadilan Negeri Tondano" className='w-full surface-200' readOnly></InputText>
                <label htmlFor="dd-city">SKPD</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <Calendar className='w-full' value={tmt} onChange={(e) => setTmt(e.value)} dateFormat="dd MM yy" showIcon />
                <label htmlFor="dd-city">Terhitung Mulai Tanggal</label>
              </span>
            </div>
            <div className='w-full surface-500 my-5' style={{height: '1px'}}></div>
            <div className='grid row-gap-4'>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <InputText className='w-full' value={noDokumen} onChange={(e) => setNoDokumen(e.target.value)}></InputText>
                <label htmlFor="dd-city">Nomor Dokumen</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <Calendar className='w-full' value={tglSurat} onChange={(e) => setTglSurat(e.value)} dateFormat="dd MM yy" showIcon />
                <label htmlFor="dd-city">Tanggal Surat</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <InputText className='w-full' value={noSkTerakhir} onChange={(e) => setNoSkTerakhir(e.target.value)}></InputText>
                <label htmlFor="dd-city">Nomor SK Terakhir</label>
              </span>
              <span className='md:col-6 sm:col-12 p-float-label'>
                <Calendar className='w-full' value={tglSkTerakhir} onChange={(e) => setTglSkTerakhir(e.value)} dateFormat="dd MM yy" showIcon />
                <label htmlFor="dd-city">Tanggal SK Terakhir</label>
              </span>

              <div className='col-12 flex justify-content-center mt-5'>
                <Button icon="pi pi-plus" label='Tambah Data' onClick={() => tambahData()}></Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default NominatifManual;
