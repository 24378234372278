import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config/APIConfig';
import '../assets/style.scss'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const TambahData = () => {
  const toast = useRef(null);
  const [golonganDropDown, setGolonganDropDown] = useState(null);
  const [golongan, setGolongan] = useState(null);
  const [masaKerja, setMasaKerja] = useState(null);
  const [selectedGolongan, setSelectedGolongan] = useState();
  const [nip, setNip] = useState('');
  const [nama, setNama] = useState('');
  const [jabatan, setJabatan] = useState('');

  const cardHeader = (
    <h2 className='mx-3'>Tambah Data Pegawai</h2>
  )

  const tambahData = async() => {
    if(nip === '' || nama === '' || jabatan === '' || selectedGolongan === null || masaKerja === null || masaKerja === ''){
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Mohon lengkapi semua data!', life: 5000 });
    }
    else {
      let masaKerjaToSend
      let availableMasaKerja = golongan.filter(item => item.golongan === selectedGolongan.golongan)
      availableMasaKerja = availableMasaKerja.sort((a, b) => a.masaKerja-b.masaKerja);

      const filter = golongan.find(item => item.golongan === selectedGolongan.golongan && item.masaKerja === masaKerja)
      if(filter){
        masaKerjaToSend = filter
      } else {
        const closest = availableMasaKerja.reduce((prev, curr) => Math.abs(curr.masaKerja - masaKerja) < Math.abs(prev.masaKerja - masaKerja) ? curr : prev);
        masaKerjaToSend = closest
      }

      try {
        await axios
        .post(API_URL + 'pegawai/add',
        {
          nip: nip,
          fullName: nama,
          golongan: selectedGolongan.golongan,
          jabatan: jabatan,
          GolonganId: masaKerjaToSend.id,
          masaKerja: masaKerjaToSend.masaKerja
        },
        {headers: {
          Accept: "*/*",
          Authorization: sessionStorage.getItem("accessToken")
        }})
        .then((res) => {
          if(res.data.result){
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.msg, life: 5000 });
            setNip('')
            setNama('')
            setSelectedGolongan()
            setJabatan('')
            setMasaKerja(null)
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
  }
  
  useEffect(() => {
    try {
      axios
      .get(API_URL + 'pegawai/golongan')
      .then((res) => {
        if(res.data.result){
          const filter = res.data.golongan.filter((ele, index) => index === res.data.golongan.findIndex( elem => elem.golongan === ele.golongan))
          setGolonganDropDown(filter)
          setGolongan(res.data.golongan)
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  },[])

  return (
    <div className='grid p-7'>
      <Toast ref={toast} />
      <div className='col-12'>
        <Card header={cardHeader} className='relative overflow-hidden p-2' style={{backgroundColor: '#b0c7bf', boxShadow: '10px 9px 15px 2px #565656'}}>
          <div className='grid'>
            <span className='md:col-6 sm:col-12'>
              <label htmlFor="nip" className="font-bold block mb-2">NIP</label>
              <InputText id='nip' className='w-full' placeholder='Masukkan NIP' value={nip} onChange={(e) => setNip(e.target.value)}></InputText>
            </span>
            <span className='md:col-6 sm:col-12'>
              <label htmlFor="nama" className="font-bold block mb-2">Nama Lengkap</label>
            <InputText id='nama' className='w-full' placeholder='Masukkan Nama' value={nama} onChange={(e) => setNama(e.target.value)}></InputText>
            </span>
            <span className='md:col-6 sm:col-12'>
              <label htmlFor="golongan" className="font-bold block mb-2">Golongan</label>
              <Dropdown inputId='golongan' className='w-full' placeholder='Pilih Golongan' value={selectedGolongan} options={golonganDropDown} optionLabel="ket" onChange={(e) => setSelectedGolongan(e.value)}></Dropdown>
            </span>
            <span className='md:col-6 sm:col-12'>
              <label htmlFor="jabatan" className="font-bold block mb-2">Jabatan</label>
              <InputText id='jabatan' className='w-full' placeholder='Masukkan Jabatan' value={jabatan} onChange={(e) => setJabatan(e.target.value)}></InputText>
            </span>
            <span className='md:col-2 sm:col-2'>
              <label htmlFor="masaKerja" className="font-bold block mb-2">Masa Kerja</label>
              <InputNumber inputClassName="w-full" inputId='masaKerja' value={masaKerja} onValueChange={(e) => setMasaKerja(e.value)} mode="decimal" showButtons min={0} max={50} />
            </span>
            <div className='col-12 flex justify-content-center mt-5'>
              <Button icon="pi pi-plus" label='Tambah Data' onClick={() => tambahData()}></Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default TambahData;
