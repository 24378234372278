import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config/APIConfig';
import { useUserContext } from '../UserContext';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import '../assets/style.scss'
import logo from '../assets/logo.png'

function LoginPage() {
    const { storeUserData } = useUserContext()
    const toast = useRef(null);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleBtn = (e) => {
        if (e.target.innerText === "Daftar"){
            navigate("/signUp", {
                replace: true
            });
        }
    }

    const handleLogin = async () => {
        try {
            await axios
            .post(API_URL + 'auth/login',
            {
              username,
              password
            },
              {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
            .then(async (res) => {
                if(res.data.result){
                    sessionStorage.setItem("accessToken", res.data.token)
                    storeUserData(res.data.user)
                    navigate("/home", {replace: true});
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
                }
          });
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        if(sessionStorage.getItem("accessToken")){
            navigate("/home", {replace: true});
        }
    }, [])

  return (
    <div className='h-screen flex justify-content-center align-items-center loginPage'>
        <Toast ref={toast} />
        <Card title="Kenaikan Gaji Berkala Pengadilan Negeri Tondano" className='h-7 w-8'>
            <div className="flex flex-column md:flex-row grid">
                <div className="w-full md:w-5 flex flex-column align-items-center justify-content-center gap-4 pt-5 col-12">
                    <span className="p-float-label">
                        <InputText id="username" type="text" onChange={(e) => setUsername(e.target.value)}/>
                        <label htmlFor="username">
                            Username
                        </label>
                    </span>
                    <span className="p-float-label">
                        <InputText id="password" type="password"  onChange={(e) => setPassword(e.target.value)}/>
                        <label htmlFor="password">
                            Password
                        </label>
                    </span>
                    <div className='mt-3 flex flex-column justify-content-center'>
                    <Button id='login' label="Masuk" icon="pi pi-user" className="w-10rem" onClick={handleLogin}></Button>
                    </div>
                </div>
                <div className="w-full md:w-2">
                    <Divider layout="vertical" className="hidden md:flex">
                        <b>Atau</b>
                    </Divider>
                    <Divider layout="horizontal" className="flex md:hidden" align="center">
                        <b>Atau</b>
                    </Divider>
                </div>
                <div className="w-full md:w-5 flex align-items-center justify-content-center py-5 relative">
                    <img src={logo} alt='logo' className='absolute top-0 w-7rem'/>
                    <Button id='signUp' label="Daftar" icon="pi pi-user-plus" className="p-button-success mt-8" onClick={(e) => handleBtn(e)}></Button>
                </div>
            </div>
        </Card>
    </div>
  );
}

export default LoginPage;
