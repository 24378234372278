import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config/APIConfig';
import { useUserContext } from '../UserContext';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../assets/style.scss'

const RiwayatKGB = () => {
    const { userData } = useUserContext()
    const authLevel = userData?.authLevel === 1 ? true : false
    const toast = useRef(null);
    const [riwayatKGB, setRiwayatKGB] = useState([]);
    const [golongan, setGolongan] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    const formatDate = (date) => {
      const newDate = new Date(date)
      return newDate.toLocaleDateString("id-ID", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      })
    }

    const tableHeader = (
      <div className='w-full flex justify-content-between align-items-center'>
        <h2>Riwayat Kenaikan Gaji Berkala</h2>
        <span className="p-input-icon-left flex align-items-center">
          <i className="pi pi-search align-self-center" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cari..." />
        </span>
      </div>
    )

    const pangkatTemp = (rowData) => {
      const pangkat = golongan.filter(item => {
        return item.golongan === rowData.pegawai.golongan && item.masaKerja === rowData.pegawai.masaKerja
      })

      return <span> {pangkat[0]?.ket} ({rowData.pegawai.golongan})</span>
    }

    useEffect(() => {
      async function fetchData() {
        try {
          await axios
          .get(API_URL + 'riwayat')
          .then((res) => {
            if(res.data.result){
              setRiwayatKGB(res.data.history)
            } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }

      async function fetchPersonalData() {
        try {
          await axios
          .post(API_URL + 'riwayat',
          {fullName: userData.fullName},
          {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }}
          )
          .then((res) => {
            if(res.data.result){
              setRiwayatKGB(res.data.history)
            } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
      
      async function fetchGolongan() {
        try {
          await axios
          .get(API_URL + 'pegawai/golongan')
          .then((res) => {
            if(res.data.result){
              setGolongan(res.data.golongan)
            } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
  
      console.log(userData)
      if(authLevel) {
        fetchData()
      } else {
        fetchPersonalData()
      }
      fetchGolongan()
    },[])

  return (
    <div className='grid p-7'>
      <div className='col-12'>
        <div className='p-card grid relative overflow-hidden p-2' style={{backgroundColor: '#b0c7bf', boxShadow: '10px 9px 15px 2px #565656'}}>
          <DataTable value={riwayatKGB} header={tableHeader} globalFilter={globalFilter} paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rows={5} rowsPerPageOptions={[5, 10, 25, 50]} sortMode="multiple" currentPageReportTemplate="Menampilkan {last} Dari {totalRecords} Data" emptyMessage="Belum Ada Data." className='w-full'>
            <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
            <Column sortable field="pegawai.fullName" header="Nama"></Column>
            <Column sortable field="pegawai.nip" header="NIP"></Column>
            <Column sortable field="pangkat" header="Pangkat / Golongan" body={pangkatTemp}></Column>
            <Column sortable header="SKPD" body="Pengadilan Negeri Tondano"></Column>
            <Column sortable field="tmt" header="TMT" body={(data) => formatDate(data.tmt)}></Column>
            <Column sortable field="status" header="Status"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}
export default RiwayatKGB;
