import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from '../UserContext';
import { API_URL } from "../config/APIConfig";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup } from 'primereact/confirmpopup';
import { confirmPopup } from 'primereact/confirmpopup';
import "../assets/style.scss";

const DaftarPegawai = () => {
  const { userData, logout } = useUserContext()
  const toast = useRef(null);
  const navigate = useNavigate();
  const [pegawai, setPegawai] = useState([]);
  const [slctdPegawai, setSlctdPegawai] = useState()
  const [globalFilter, setGlobalFilter] = useState(null);
  const [auth, setAuth] = useState(sessionStorage.getItem("accessToken"))
  const [loading, setLoading] = useState(false)

  const tableHeader = (
    <div className="w-full flex justify-content-between align-items-center">
      <h2>Daftar Pegawai</h2>
      <span className="p-input-icon-left flex align-items-center">
        <i className="pi pi-search align-self-center" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Cari..."
        />
      </span>
    </div>
  );

  const golonganTemplate = (data) => {
    return <span>{data.golonganDetail.ket} / {data.golongan}</span>
  }

  const confirmDelete = (e, rowData) => {
    confirmPopup({
        target: e.currentTarget,
        message: 'Apakah anda yakin ingin menghapus pegawai ini?',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (e) => deleteData(e, rowData),
    });
};

  const removeBtn = (rowData, options) => {
    return <Button icon="pi pi-trash" onClick={(e) => confirmDelete(e, rowData)} />
  }

  const deleteData = async(e, rowData) => {
    try {
      await axios
      .post(API_URL + 'pegawai/delete',
      {
        id: rowData.id
      },
      {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
      .then((res) => {
          if(res.data.result){
            toast.current.show({ severity: 'success', summary: 'Sukses', detail: res.data.msg, life: 5000 });
            setLoading(!loading)
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
          }
    });
    } catch (error) {
        console.error(error.message);
    }
  }

  useEffect(() => {
    try {
      axios
      .get(API_URL + 'pegawai')
      .then((res) => {
          if(res.data.result){
              setPegawai(res.data.pegawai)
          } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
          }
    });
    } catch (error) {
      console.error(error.message);
    }
  }, [loading]);

  return (
    <div className="grid p-7">
    <Toast ref={toast} />
    <ConfirmPopup />
      <div className="col-12">
        <div
          className="p-card grid relative overflow-hidden p-2"
          style={{
            backgroundColor: "#b0c7bf",
            boxShadow: "10px 9px 15px 2px #565656",
          }}
        >
          <DataTable
            value={pegawai}
            header={tableHeader}
            globalFilter={globalFilter}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            sortMode="multiple"
            selectionMode="single"
            selection={slctdPegawai}
            onSelectionChange={(e) => setSlctdPegawai(e.value)}
            dataKey="nip"
            currentPageReportTemplate="Menampilkan {last} Dari {totalRecords} Data"
            emptyMessage="Belum Ada Data."
            className="w-full"
          >
            <Column
              header="No"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
            ></Column>
            <Column
              sortable
              field="nip"
              header="NIP"
              className="w-25rem"
            ></Column>
            <Column sortable field="fullName" header="Nama"></Column>
            <Column
              sortable
              field="golongan"
              header="Pangkat / Golongan"
              className="w-20rem"
              body={(data) => golonganTemplate(data)}
            ></Column>
            {userData?.authLevel === 1 && <Column style={{width: 40}} body={removeBtn}></Column>}
          </DataTable>
        </div>
      </div>
    </div>
  );
};
export default DaftarPegawai;
