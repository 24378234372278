// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'primereact/resources/primereact.css';
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/tailwind-light/theme.css';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import HomeRoutes from './components/HomeRoutes';
import { UserProvider } from './UserContext';

function App() {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<LoginPage/>} />
        <Route path="/signUp" element={<SignUpPage />} />
        <Route path='*' element={<HomeRoutes/>} />
      </Routes>
    </UserProvider>
  );
}

export default App;
