import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../config/APIConfig";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { confirmPopup } from 'primereact/confirmpopup';
import "../assets/style.scss";

const DaftarNominatif = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [nominatif, setNominatif] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [history, setHistory] = useState();
  const [showHistory, setShowHistory] = useState(false);

  const handleClick = (e, data) => {
    if(e.target.id === "proses"){
      confirmPopup({
        target: e.currentTarget,
        message: 'Apakah anda yakin untuk proses nominatif ini?',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => apiReq(e, data))
    });
    } else {
      apiReq(e, data)
    }
  }

  const apiReq = async(e, data) => {
    let url
    let payload = {}
    let golongan

    if(e.target.id === "proses"){
      url = "nominatif/proses"
      payload.id = data.id
      payload.idPegawai = data.idPegawai
      payload.masaKerja = data.masaKerjaBaru
      payload.GolonganIdBaru = data.GolonganIdBaru

      try{
        const response = await axios.post(API_URL + 'pegawai/cekGolongan', { GolonganId: data.pegawai.GolonganId }, {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }});
        golongan = response.data.golongan;
      } catch (err) {
        console.log(err)
      }
    } else if(e.target.id === "history"){
      url = "nominatif/history"
      payload.idPegawai = data.idPegawai
    }

    try {
      await axios
      .post(API_URL + url,
      payload,
      {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }}
      )
      .then((res) => {
        if(res.data.result){
          if(e.target.id === "history"){
            setHistory(res.data.history)
            setShowHistory(true)
          } else if (e.target.id === "proses"){
            navigate('/buatSurat', {state: {nominatif: data, golongan: golongan}})
          }
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }

  const tableHeader = (
    <div className="w-full flex justify-content-between align-items-center">
      <h2>Daftar Nominatif</h2>
      <span className="p-input-icon-left flex align-items-center">
        <i className="pi pi-search align-self-center" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Cari..."
        />
      </span>
    </div>
  );

  const actionBtn = (options) => {
    return (
      <div className="flex justify-content-around">
        <Button id="proses" icon="pi pi-check-circle pointer-events-none" onClick={(e) => handleClick(e, options)} tooltip="Proses Kenaikan Gaji"/>
        <Button id="history" icon="pi pi-history pointer-events-none" onClick={(e) => handleClick(e, options)}  className="ml-1" tooltip="Riwayat Kenaikan Gaji Berkala"/>
      </div>
    );
  };

  const formatDate = (date) => {
    const newDate = new Date(date)
    return newDate.toLocaleDateString("id-ID", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    })
  }

  const nextKgbTemp = (rowData) => {
    let tmt = new Date(rowData.tmt)
    let nextKgb = new Date(tmt.setFullYear(tmt.getFullYear() + 2))

    return formatDate(nextKgb)
  }

  useEffect(() => {
    async function nominatif() {
      try {
        await axios
        .get(API_URL + 'nominatif')
        .then((res) => {
          if(res.data.result){
            setNominatif(res.data.nominatif)
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }

    nominatif()
  },[])

  return (
    <div className="grid p-7">
      <Toast ref={toast} />
      <ConfirmPopup />
      <div className="col-12">
        <div
          className="p-card grid relative overflow-hidden p-2"
          style={{
            backgroundColor: "#b0c7bf",
            boxShadow: "10px 9px 15px 2px #565656",
          }}
        >
          <DataTable
            value={nominatif}
            header={tableHeader}
            globalFilter={globalFilter}
            paginator
            showGridlines
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[5, 10, 25, 50]}
            rows={5}
            sortMode="multiple"
            currentPageReportTemplate="Menampilkan {last} Dari {totalRecords} Data"
            emptyMessage="Belum Ada Data."
            className="w-full"
          >
            <Column
              header="Aksi"
              headerStyle={{ width: "3rem" }}
              body={actionBtn}
            ></Column>
            <Column
              header="No"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
            ></Column>
            <Column
              sortable
              field='pegawai.fullName'
              header="Nama Lengkap"
            ></Column>
            <Column
              sortable
              field='pegawai.nip'
              header="NIP"
            ></Column>
            <Column
              sortable
              field="tmt"
              header="Terhitung Mulai Tanggal"
              body={(data) => formatDate(data.tmt)}
            ></Column>
            <Column
              sortable
              field="tmt"
              header="KGB Selanjutnya"
              body={nextKgbTemp}
            ></Column>
          </DataTable>
        </div>
      </div>
      <Dialog header="Riwayat Kenaikan Gaji Berkala" visible={showHistory} onHide={() => setShowHistory(false)}>
        <DataTable
            value={history}
            paginator
            showGridlines
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[5, 10, 25, 50]}
            rows={5}
            sortMode="multiple"
            currentPageReportTemplate="Menampilkan {last} Dari {totalRecords} Data"
            emptyMessage="Belum Ada Data."
            className="w-full"
          >
            <Column
              header="No"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
            ></Column>
            <Column
              sortable
              field="pegawai.fullName"
              header="Nama Lengkap"
            ></Column>
            <Column
              sortable
              field="pegawai.nip"
              header="NIP"
            ></Column>
            <Column
              sortable
              field="tglSurat"
              header="Tanggal Surat"
              body={(data) => formatDate(data.tglSurat)}
            ></Column>
            <Column
              sortable
              field="status"
              header="Status"
            ></Column>
        </DataTable>
      </Dialog>
    </div>
  );
};
export default DaftarNominatif;
