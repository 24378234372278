import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import dahsboardImg from '../assets/dashboardImg.png'
import '../assets/style.scss'

const Dashboard = () => {
    const navigate = useNavigate();

    const handleClick = (goTo) => {

    }

  return (
    <div className='grid p-7'>
      <div className='col-12'>
        <div className='p-card grid relative overflow-hidden' style={{backgroundColor: '#b0c7bf', boxShadow: '10px 9px 15px 2px #565656'}}>
          <div className='col-6 p-5 flex align-items-center justify-content-center relative z-2'>
            <div className='w-10 border-2 border-solid border-300 p-5 border-round-xl' style={{boxShadow: '10px 9px 15px 2px #a1a1a1'}}>
              <div className='w-full flex flex-column align-items-center relative' style={{top: '-95px'}}>
                <h1 className='text-5xl text-600 p-1' style={{backgroundColor: '#ecf4f0', width: 'fit-content'}}>Pengadilan Negeri Tondano</h1>
                <h5 className='m-0 text-3xl text-600 relative' style={{top: '-25px'}}>Provinsi Sulawesi Utara</h5>
              </div>
              <span className='-mt-8 mb-2 text-xl flex w-11 text-center font-semibold text-600'>Pengadilan Negeri Tondano merupakan lembaga peradilan di lingkungan Peradilan Umum yang berkedudukan di Kabupaten Minahasa, Provinsi Sulawesi Utara.</span>
              <div className="w-full border-none border-bottom-1 border-solid border-300 mb-5 flex align-items-center justify-content-center" > 
                <span className='ml-4 mb-2 flex text-2xl font-bold text-green-800 relative p-2' style={{backgroundColor: '#f1f7f5', top: '29px', width: 'fit-content'}}>Hubungi:</span>
              </div>
              <table className='ml-4'>
                <tbody>
                  <tr className='flex'>
                    <th className='font-semibold text-sm text-left w-10rem text-green-800'>Telepon</th>
                    <td className='font-semibold text-sm w-1rem text-green-800'>:</td>
                    <td className='mr-3 text-sm font-semibold text-green-800'>(0431) 321122</td>
                  </tr>
                  <tr className='flex'>
                    <th className='font-semibold text-sm text-green-800 text-left flex align-items-start w-10rem'>Alamat</th>
                    <td className='font-semibold text-sm text-green-800 w-1rem'>:</td>
                    <td className='mr-3 text-sm w-20rem font-semibold text-green-800'>Jl. Manguni No.75, Kembuan, Kec. Tondano Utara, Kabupaten Minahasa, Sulawesi Utara</td>
                  </tr>
                  <tr className='flex'>
                    <th className='font-semibold text-sm text-green-800 text-left w-10rem'>Jam Operasional</th>
                    <td className='font-semibold text-sm text-green-800 w-1rem'>:</td>
                    <td className='w-8rem text-sm font-semibold text-green-800'>Senin-Kamis</td>
                    <td className='font-semibold text-sm text-green-800'>08:00 – 04:30</td>
                  </tr>
                  <tr className='flex'>
                    <th className='w-10rem'></th>
                    <td className='w-1rem'></td>
                    <td className='w-8rem text-sm font-semibold text-green-800'>Jumat</td>
                    <td className='font-semibold text-sm text-green-800'>08:00 – 05:00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='absolute bottom-0 w-full mb-4 flex justify-content-around'>
              <Button className='text-lg' text severity='secondary' label='Facebook' icon="pi pi-facebook" onClick={handleClick("facebook")}/>
              <Button className='text-lg' text severity='secondary' label='Instagram' icon="pi pi-instagram" onClick={handleClick("instagram")}/>
              <Button className='text-lg' text severity='secondary' label='Twitter' icon="pi pi-twitter" onClick={handleClick("twitter")}/>
            </div>
          </div>
          <div className='col-6 flex justify-content-end pr-5 relative'>
            <svg className='absolute' style={{transform: 'scale(1.8)', top: '100px', left: '-780px'}} width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" fill="url(#paint0_linear)">
                <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="5s"
                
                values="M364.504 0.00131368C416.594 0.168265 466.139 20.1059 512.391 44.0676C557.895 67.642 602.302 96.1253 631.434 138.289C659.943 179.551 664.767 231.184 675.519 280.171C686.2 328.838 699.684 376.947 694.692 426.522C689.39 479.177 679.959 535.064 645.785 575.472C611.738 615.729 556.092 627.656 507.429 647.946C460.761 667.403 415.021 690.642 364.504 692.748C313.131 694.889 261.064 684.352 215.724 660.104C171.077 636.226 142.122 593.131 108.11 555.626C72.2421 516.076 25.1071 483.213 9.10745 432.275C-7.10404 380.663 -0.388863 322.784 19.1253 272.328C37.8438 223.929 83.0317 193.02 116.859 153.669C149.559 115.629 173.536 69.5665 216.159 43.1182C260.47 15.6227 312.356 -0.165822 364.504 0.00131368Z;
                
                
                M332 0.500017C384.091 0.666969 433.248 15.5383 479.5 39.5C525.005 63.0744 601.369 82.3364 630.5 124.5C659.009 165.762 634.815 216.013 645.567 265C656.247 313.667 644.058 369.701 639.067 419.275C633.765 471.931 650.501 499.092 616.326 539.5C582.28 579.757 545.947 600.711 497.284 621C450.616 640.457 417.41 678.395 366.893 680.5C315.52 682.641 234.734 673.749 189.393 649.5C144.746 625.623 135.765 553.505 101.752 516C65.8847 476.45 29.4999 470.213 13.5002 419.275C-2.71131 367.663 -6.01387 315.456 13.5002 265C32.2188 216.601 67.9245 195.852 101.752 156.5C134.452 118.461 131.877 52.4483 174.5 26C218.811 -1.49547 279.852 0.332881 332 0.500017Z;
                
                M364.504 0.00131368C416.594 0.168265 466.139 20.1059 512.391 44.0676C557.895 67.642 602.302 96.1253 631.434 138.289C659.943 179.551 664.767 231.184 675.519 280.171C686.2 328.838 699.684 376.947 694.692 426.522C689.39 479.177 679.959 535.064 645.785 575.472C611.738 615.729 556.092 627.656 507.429 647.946C460.761 667.403 415.021 690.642 364.504 692.748C313.131 694.889 261.064 684.352 215.724 660.104C171.077 636.226 142.122 593.131 108.11 555.626C72.2421 516.076 25.1071 483.213 9.10745 432.275C-7.10404 380.663 -0.388863 322.784 19.1253 272.328C37.8438 223.929 83.0317 193.02 116.859 153.669C149.559 115.629 173.536 69.5665 216.159 43.1182C260.47 15.6227 312.356 -0.165822 364.504 0.00131368Z"/>
              </path>
              <defs>
              <linearGradient id="paint0_linear" x1="149" y1="128" x2="432.5" y2="693" gradientUnits="userSpaceOnUse">
              <stop stopColor="#e6f0ec"/>
              <stop offset="1" stopColor="#ffffff" stopOpacity="0.97"/>
              </linearGradient>
              </defs>
            </svg>
            <img src={dahsboardImg} alt='pengadilan' className='w-11 z-2'></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
