import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../assets/logo.png';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const PdfGenerator = () => {
    const pdfRef = useRef();
    const location = useLocation();
    const data = location.state.nominatif
    const dataGolongan = location.state.golongan

    const [nama, setNama] = useState('')
    const [nip, setNip] = useState('')

    const formatDate = (date) => {
        const newDate = new Date(date)
        return newDate.toLocaleDateString("id-ID", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        })
    }

    const generatePDF = async () => {
      const Component = document.getElementById('pdf-content');

      await html2canvas(Component, {scale: 2}).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");

        const pdf = new jsPDF({
            orientation: "landscape", // landscape or portrait
            unit: "mm",
            format: "a4",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const margin = 0.1;
    
        const pdfWidth = pdf.internal.pageSize.width * (1 - margin);
        const pdfHeight = pdf.internal.pageSize.height * (1 - margin);
    
        const x = pdf.internal.pageSize.width * (margin / 2);
        const y = pdf.internal.pageSize.height * (margin / 2);
    
        const widthRatio = pdfWidth / imgProps.width;
        const heightRatio = pdfHeight / imgProps.height;
        const ratio = Math.min(widthRatio, heightRatio);
        
        const w = imgProps.width * ratio;
        const h = imgProps.height * ratio;
    
        pdf.addImage(imgData, "JPEG", x, y, w, h);
        pdf.save('download.pdf')
      })
    };

  return (
    <div className='flex flex-column'>
      <div id="pdf-content" className='p-4' ref={pdfRef}>
        {/* Header */}
        <div className='flex'>
          <img src={logo} alt="Logo" className='w-4rem absolute' />
          <div className='w-full flex flex-column justify-content-center align-item-center font-bold text-center'>
            <span className='text-2xl'>PENGADILAN NEGERI TONDANO</span>
            <span className=''>Alamat: Jl. Manguni No.75, Kembuan, Kec. Tondano Utara, Kabupaten Minahasa, Sulawesi Utara</span>
            <span className=''>Telp: (0431) 321122</span>
          </div>
        </div>
        <hr className='border-0'/>

        {/* Body */}
        <div className='flex justify-content-between'>
          {/* Left side */}
          <div className='flex flex-column'>
            <div className='flex mb-1 mt-3'>
                <span className='flex w-9rem'>NIP</span>
                <span>: {data.pegawai.nip}</span>
            </div>
            <div className='flex my-2'>
                <span className='flex w-9rem'>Nama</span>
                <span>: {data.pegawai.fullName}</span>
            </div>
            <div className='flex my-2'>
                <span className='flex w-9rem'>Pangkat/Golongan</span>
                <span>: {dataGolongan.ket} ({data.pegawai.golongan})</span>
            </div>
            <div className='flex my-1'>
                <span className='flex w-9rem'>SKPD</span>
                <span>: Pengadilan Negeri Tondano</span>
            </div>
          </div>

          {/* Right side */}
          <div className='flex flex-column w-20rem'>
            <div className='flex mb-1 mt-3'>
                <span className='flex w-9rem'>Nomor Surat</span>
                <span>: {data.noDokumen}</span>
            </div>
            <div className='flex mb-1 mt-3'>
                <span className='flex w-9rem'>Tanggal Surat</span>
                <span>: {formatDate(data.tglSurat)}</span>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className='flex flex-column mt-8'>
            <span className='font-bold mb-3'>Ditetapkan:</span>
            <div className='flex flex-column border-500 border-1 border-solid'>
                <div className='flex border-500 border-bottom-2 bg-green-700 text-yellow-200'>
                    <span className='w-4 p-2 border-500 font-bold'>Keterangan</span>
                    <span className='w-4 p-2 border-500 border-x-1 font-bold'>Kenaikan Gaji Berkala Lama</span>
                    <span className='w-4 p-2 border-500 font-bold'>KGB Baru</span>
                </div>
                <div className='flex border-500 border-bottom-1'>
                    <span className='w-4 p-1 px-2 border-500'>Terhitung Mulai Tanggal</span>
                    <span className='w-4 p-1 px-2 border-500 border-x-1'>{formatDate(data.tglSkTerakhir)}</span>
                    <span className='w-4 p-1 px-2 border-500'>{formatDate(data.tmt)}</span>
                </div>
                <div className='flex border-500 border-bottom-1'>
                    <span className='w-4 p-1 px-2 border-500'>Pangkat / Golongan</span>
                    <span className='w-4 p-1 px-2 border-500 border-x-1'>{dataGolongan.ket} ({data.pegawai.golongan})</span>
                    <span className='w-4 p-1 px-2 border-500'>{dataGolongan.ket} ({data.pegawai.golongan})</span>
                </div>
                <div className='flex border-500 border-bottom-1'>
                    <span className='w-4 p-1 px-2 border-500'>Masa Kerja</span>
                    <span className='w-4 p-1 px-2 border-500 border-x-1'>{data.pegawai.masaKerja} Tahun</span>
                    <span className='w-4 p-1 px-2 border-500'>{data.masaKerjaBaru} Tahun</span>
                </div>
                <div className='flex'>
                    <span className='w-4 p-1 px-2 border-500'>Gaji</span>
                    <span className='w-4 p-1 px-2 border-500 border-x-1'>Rp. {data.gajiLama}</span>
                    <span className='w-4 p-1 px-2 border-500'>Rp. {data.gajiBaru}</span>
                </div>
            </div>
        </div>

        <div className='flex flex-column align-items-center mt-3 ml-4 w-max'>
          <span className='font-bold w-max' style={{width: 150}}>KETUA PENGADILAN NEGERI TONDANO</span>
          <div className='flex flex-column mt-8'>
            <InputText value={nama} onChange={(e) => setNama(e.value)} className='border-none p-0 mt-6 font-bold' style={{width: 250, fontFamily: 'none', color: 'black'}} />
            <span className='border-none p-0  font-bold' style={{width: 250, fontFamily: 'none', color: 'black'}}>NOVA L. SASUBE, S.H. M.H.</span> 
            <div className='border-bottom border-1' style={{width: 250}}/>
            <span className='font-bold' style={{width: 250}}>NIP.19731125 199903 2 003 <InputText value={nip} onChange={(e) => setNip(e.value)} className='border-none p-0 font-bold' style={{width: 215, fontFamily: 'none', color: 'black'}} /></span>
          </div>
        </div>
      </div>
      
      <Button icon='pi pi-print mr-2' className='ml-4 mt-4 w-6rem bg-green-700 border-none' onClick={generatePDF}>Cetak</Button>
    </div>
  );
};

export default PdfGenerator;
