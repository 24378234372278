import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config/APIConfig';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import Logo from '../assets/logo.png';

import Dashboard from '../pages/Dashboard';
import RouteConfig from '../config/RouteConfig';

export function HomeRoutes() {
    const { userData, logout } = useUserContext()
    const authLevel = userData?.authLevel === 1 ? true : false
    const toast = useRef(null);
    const navigate = useNavigate();
    // const [selectedFile, setSelectedFile] = useState(null)
    const [sandiLama, setSandiLama] = useState('')
    const [sandiBaru, setSandiBaru] = useState('')
    const [sandiBaru2, setSandiBaru2] = useState('')
    const [sideMenu, setSideMenu] = useState(false)
    const [visible, setVisible] = useState(false)
    const [InfoPopUp, setInfoPopUp] = useState(false)
    // const [profil, setProfil] = useState(false)
    const [data, setData] = useState()
    const [golongan, setGolongan] = useState()

    const [passLamaErr, setPassLamaErr] = useState()
    const [passErr, setPassErr] = useState()
    const [passClassName, setPassClassName] = useState('')
    const profilMenuRef = useRef(null);
    const profilItems = [
        {
            label: 'Profil',
            items: [
                {
                    label: 'Informasi Pribadi',
                    icon: 'pi pi-info-circle',
                    command: () => handleBtn("informasiPribadi")
                },
                {
                    label: 'Ubah Password',
                    icon: 'pi pi-lock',
                    command: () => handleBtn("ubahPassword")
                },
            ]
        },
        {
            label: 'Keluar',
            icon: 'pi pi-power-off',
            command: () => handleBtn("keluar")
        }
    ]
    const profilItemsAdmin = [
        {
            label: 'Profil',
            items: [
                {
                    label: 'Ubah Password',
                    icon: 'pi pi-lock',
                    command: () => handleBtn("ubahPassword")
                },
            ]
        },
        {
            label: 'Keluar',
            icon: 'pi pi-power-off',
            command: () => handleBtn("keluar")
        }
    ]

    const handleBtn = (command) => {
        if(command === "keluar") {
            sessionStorage.clear()
            logout()
            navigate("/", {
                replace: true
            });
        } else if(command === "ubahPassword"){
            setVisible(true)
        } else if(command === "pengaturanProfil"){
            // setProfil(true)
        } else if (command === "informasiPribadi"){
            setInfoPopUp(true)
        } else if(command.target.id === "submitPass"){
            if(sandiBaru === sandiBaru2){
                apiReq()
            } else {
                setPassErr("Password baru yang dimasukkan tidak cocok!")
            }
        }
    }

    // const handleUpload = async() => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('profilePicture', selectedFile);

    //         await
    //         axios.post(`${API_URL}updatePP`, {id: userData.id, formData})
    //         .then((res) => {
    //             if(res.data.result){
    //                 setSelectedFile(null)
    //                 toast.current.show({ severity: 'success', summary: 'Sukses', detail: res.data.msg, life: 5000 });
    //             } else {
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
    //             }
    //         })
    //       } catch (error) {
    //         console.error('Error:', error.response.data.error || error.message);
    //       }
    // }

    const apiReq = async() => {
        var passCorrect
        try {
            await axios
            .post(API_URL + 'auth/login',
            {
              username: userData.username,
              password: sandiLama
            },
              {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
            .then((res) => {
                if(res.data.result){
                    passCorrect = true
                } else {
                    passCorrect = false
                    setPassLamaErr('Password anda salah!')
                }
          });
        } catch (error) {
            console.error(error.message);
        }

        if (passCorrect){
            try {
                await axios
                .post(API_URL + 'auth/changePass',
                  {
                    id: userData.id,
                    password: sandiBaru,
                  },
                  {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }})
                .then(async (res) => {
                    if(res.data.result){
                        setVisible(false)
                        setPassErr()
                        setSandiLama()
                        setSandiBaru()
                        setSandiBaru2()
                        toast.current.show({ severity: 'success', summary: 'Sukses', detail: res.data.msg, life: 5000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
                    }
              });
            } catch (error) {
                console.error(error.message);
            }
        }
    }

    const menuHeader = (
        <h2 className='mr-8 pr-8'>MENU</h2>
    )

    useEffect(() => {
        if(!sessionStorage.getItem("accessToken")){
            navigate("/", {
                replace: true
            });
        }
    }, [])

    useEffect(() => {
      async function fetchPersonalData() {
        try {
          await axios
          .post(API_URL + 'pegawai/personalData',
          {fullName: userData.fullName, nip: userData.nip},
          {headers: {
        Accept: "*/*",
        Authorization: sessionStorage.getItem("accessToken")
      }}
          )
          .then((res) => {
            if(res.data.result){
              setData(res.data.data[0])
            } else {
              toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }

      fetchPersonalData()
    },[])

    useEffect(() => {
        async function fetchGolongan() {
            try {
              await axios
              .get(API_URL + 'pegawai/golongan')
              .then((res) => {
                if(res.data.result){
                  let gol = res.data.golongan
                  
                  const filter = gol.filter(item => {return item.golongan === data?.golongan && item.masaKerja === data?.masaKerja})
                  
                  setGolongan(filter[0])
                } else {
                  toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.msg, life: 5000 });
                }
              });
            } catch (error) {
              console.error(error.message);
            }
        }
        
        fetchGolongan()
    },[data])

    return (
        <div className='main-container'>
            <Toast ref={toast}></Toast>
            <div className='topbar p-3 flex justify-content-between' style={{boxShadow: '0 0 5px .5px #c9c9c9'}}>
                <div className='flex'>
                    <Button icon="pi pi-bars" className='mx-3' rounded text severity="secondary" aria-label="Cancel" onClick={() => setSideMenu(true)}/>
                    <img src={Logo} alt='logo' className='w-3rem h-3rem'/>
                    <span className='ml-2 text-3xl align-self-center font-bold text-green-800'>Pengadilan Negeri Tondano</span>
                </div>
                <div className='flex'>
                    <Avatar icon="pi pi-user" size="large" shape="circle" style={{borderRadius: '50%', cursor: 'pointer'}} />
                    <Menu model={userData?.authLevel === 1 ? profilItemsAdmin : profilItems} popup ref={profilMenuRef} id="profil_menu"/>
                    <Button iconPos="right" label={userData?.fullName} icon="pi pi-angle-down" text severity="secondary" onClick={(event) => profilMenuRef.current.toggle(event)} aria-controls="profil_menu" aria-haspopup />
                </div>
            </div>
            <Sidebar visible={sideMenu} position="left" onHide={() => setSideMenu(false)} icons={menuHeader} style={{backgroundColor: '#d5dfdc'}}>
                <div className='grid mt-0'>
                    <i className='col-1 pi pi-home'></i> <Link to="/home" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Beranda</Link>
                    <i className='col-1 pi pi-users'></i> <Link to="/daftarPegawai" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Daftar Pegawai</Link>
                    {authLevel && (
                        <>
                            <i className='col-1 pi pi-user-plus'></i>
                            <Link to="/tambahData" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Tambah Data</Link>
                            <i className='col-1 pi pi-list'></i>
                            <Link to="/daftarNominatif" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Daftar Nominatif</Link>
                            <i className='col-1 pi pi-plus-circle'></i>
                            <Link to="/nominatifManual" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Nominatif Manual</Link>
                        </>
                    )}
                    <i className='col-1 pi pi-history'></i> <Link to="/riwayatKGB" className='col-11 text-left text-800 no-underline' onClick={() => setSideMenu(false)}>Riwayat KGB</Link>
                </div>
            </Sidebar>
            <div className='context-container overflow-hidden'>
                <Routes>
                    <Route path='/home' extract element={<Dashboard />} />
                    {RouteConfig.map((route, i) => {return <Route key={i} path={route.path} element={route.element} /> })}
                </Routes>
            </div>
            <Dialog header="Ubah Password" visible={visible} onHide={() => {setVisible(false); setPassErr(); setPassLamaErr(); setSandiLama(); setSandiBaru(); setSandiBaru2()}} style={{minHeight: '60vh'}}>
                <div className="w-full flex flex-column align-items-center gap-5 pt-4 pb-4 relative" style={{height: '55vh'}}>
                    <span className="p-float-label w-full">
                        <Password id="sandiLama" inputClassName='w-20rem' value={sandiLama} className={passLamaErr ? 'p-invalid' : ''} onChange={(e) => {setSandiLama(e.target.value); setPassLamaErr()}} feedback={false} />
                        <label htmlFor="sandiLama">
                            Masukkan Kata Sandi Lama
                        </label>
                    </span>
                    {passLamaErr && (<span className='text-red-600 text-sm -mt-5'>{passLamaErr}</span>)}
                    <span className={`p-float-label w-full ${passClassName}`}>
                        <Password id="sandiBaru" inputClassName='w-20rem' onFocus={() => setPassClassName('mb-6')} onBlur={() => setPassClassName('')} className={passErr ? 'p-invalid' : ''} value={sandiBaru} onChange={(e) => setSandiBaru(e.target.value)} toggleMask />
                        <label htmlFor="sandiBaru">
                            Masukkan Kata Sandi Baru
                        </label>
                    </span>
                    <span className="p-float-label w-full">
                        <Password id="sandiBaru2" inputClassName='w-20rem' className={passErr ? 'p-invalid' : ''} value={sandiBaru2} onChange={(e) => {setSandiBaru2(e.target.value); setPassErr()}} toggleMask />
                        <label htmlFor="sandiBaru2">
                            Masukkan Kembali Kata Sandi Baru
                        </label>
                    </span>
                    {passErr && (<span className='text-red-600 text-sm -mt-5'>{passErr}</span>)}
                    <Button id='submitPass' label='Ubah Password' className='absolute bottom-0' onClick={(e) => handleBtn(e)}/>
                </div>
            </Dialog>
            <Dialog header="Informasi Pribadi" visible={InfoPopUp} onHide={() => setInfoPopUp(false)} style={{minHeight: '60vh', width: '50vh'}}>
                <div className="font-bold w-full flex flex-column align-items-center gap-4 pt-4 pb-4 relative" style={{height: '55vh'}}>
                <div className='flex flex-column w-full'>
                    <label>Nama Lengkap</label>
                    <InputText  value={data?.fullName} readOnly />
                </div>
                <div className='flex flex-column w-full'>
                    <label>NIP</label>
                    <InputText value={data?.nip} readOnly />
                </div>
                <div className='flex flex-column w-full'>
                    <label>Golongan</label>
                    <InputText value={`${data?.golongan} / ${golongan?.ket}`} readOnly />
                </div>
                <div className='flex flex-column w-full'>
                    <label>Jabatan</label>
                    <InputText value={data?.jabatan} readOnly />
                </div>
                <div className='flex flex-column w-full'>
                    <label>Masa Kerja</label>
                    <InputText value={`${data?.masaKerja} Tahun`} readOnly />
                </div>
                <div className='flex flex-column w-full'>
                    <label>Gaji Pokok</label>
                    <InputText value={`Rp. ${golongan?.gajiPokok}`} readOnly />
                </div>
                </div>
            </Dialog>
            {/* <Dialog header="Pengaturan Profil" visible={profil} onHide={() => {setProfil(false); setPassErr(); }} style={{minHeight: '60vh'}}>
                <div className="w-full flex flex-column align-items-center gap-5 pt-4 pb-4 relative" style={{height: '55vh'}}>
                    <FileUpload mode="basic" name="file" accept="image/*" maxFileSize={1000000} customUpload uploadHandler={() => handleUpload()} onChange={(e) => setSelectedFile(e.target.files[0])}/>
                    
                    <span className={`p-float-label w-full ${passClassName}`}>
                        <Password id="sandiBaru" inputClassName='w-20rem' onFocus={() => setPassClassName('mb-6')} onBlur={() => setPassClassName('')} className={passErr ? 'p-invalid' : ''} value={sandiBaru} onChange={(e) => setSandiBaru(e.target.value)} toggleMask />
                        <label htmlFor="sandiBaru">
                            Masukkan Kata Sandi Baru
                        </label>
                    </span>
                    <Button id='submitPass' label='Ubah Password' className='absolute bottom-0' onClick={(e) => handleBtn(e)}/>
                </div>
            </Dialog> */}
        </div>
    );
}
export default HomeRoutes;