import TambahData from '../pages/TambahData';
import DaftarNominatif from  '../pages/DaftarNominatif';
import NominatifManual from '../pages/NominatifManual';
import DaftarPegawai from '../pages/DaftarPegawai';
import RiwayatKGB from '../pages/RiwayatKgb';
import PdfGenerator from '../components/GeneratePdf';

const RouteConfig = [
  { path: '/tambahData', element: <TambahData /> },
  { path: '/daftarNominatif', element: <DaftarNominatif /> },
  { path: '/nominatifManual', element: <NominatifManual /> },
  { path: '/daftarPegawai', element: <DaftarPegawai /> },
  { path: '/riwayatKGB', element: <RiwayatKGB /> },
  { path: '/buatSurat', element: <PdfGenerator /> },

];
export default RouteConfig;